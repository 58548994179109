
import React, { useEffect } from "react"
import API from '@aws-amplify/api';
import { navigate } from "gatsby"


const KlarnaHosted = ({ data, location }) => {
  const [info, setInfo] = React.useState({});
  const [status, setStatus] = React.useState("Loading")
  const [returnURL, setReturnURL] = React.useState({
    status: 'checkout',
    response: {
      returnUrl: "/"
    }
  })

  useEffect(() => {
    console.log("INFO", info)
    if (status == "Failed") {
      console.log("FAILED")
    } else if (status == "Loaded") {
      try {
        var checkoutContainer = document.getElementById('checkout-container')
        console.log("CONTAINER", checkoutContainer)
        checkoutContainer.innerHTML = info.response.html_snippet
        var scriptsTags = checkoutContainer.getElementsByTagName('script')
        // This is necessary otherwise the scripts tags are not going to be evaluated
        for (var i = 0; i < scriptsTags.length; i++) {
          var parentNode = scriptsTags[i].parentNode
          var newScriptTag = document.createElement('script')
          newScriptTag.type = 'text/javascript'
          newScriptTag.text = scriptsTags[i].text
          parentNode.removeChild(scriptsTags[i])
          parentNode.appendChild(newScriptTag)
        }
      } catch (e) {
        console.log("SOME ERROR", e);
        setStatus("Failed")
        // Handle error.
      }
    } else if (info && info.hasOwnProperty('response') && info.response.hasOwnProperty('html_snippet') && status == "Loading") {
      setStatus("Loaded")
    } else if (status == "Loading") {
      const type = new URLSearchParams(location.search).get("type") || ""
      if (type == "checkout") {
        setStatus("Checkout")
      } else if (type == "confirm") {
        const id = new URLSearchParams(location.search).get("id") || ""
        const sid = new URLSearchParams(location.search).get("sid") || ""
        const data = {
          body: {
            "id": id,
            "sid": sid
          },
          headers: {
            "Content-Type": "application/json"
          }
        };
        API
          .post('api', '/payments/korder', data)
          .then(response => {
            console.log("SOME KLARNA RESPONSE", response)

            const snipcartData = {
              body: {
                "id": id,
                "sid": sid
              },
              headers: {
                "Content-Type": "application/json"
              }
            };
            console.log("CALLING CONFIRM API WITH", snipcartData)
            API
              .post('api', '/payments/confirm', snipcartData)
              .then(snipcartResponse => {
                console.log("SOME RESPONSE", snipcartResponse)
                // SET RESPONSE EVEN IF SNIPCART ERRORED
                // KLARNA WILL RETRY
                if (snipcartResponse.hasOwnProperty('returnUrl')) {
                  navigate(snipcartResponse.returnUrl)
                } else {
                  setInfo({
                    status: "confirm",
                    id: id,
                    sid: sid,
                    response: response
                  })
                }
                // setReturnURL({
                //   status: 'complete',
                //   response: snipcartResponse
                // })
              })
              .catch(snipcartError => {
                console.log("SOME ERROR", snipcartError);
                // SET RESPONSE EVEN IF SNIPCART ERRORED
                // KLARNA WILL RETRY
                setStatus("Failed")
                // setReturnURL({
                //   status: 'processing'
                // })
              })


            // setInfo({
            //   status: "confirm",
            //   id: id,
            //   sid: sid,
            //   response: response
            // })
          })
          .catch(error => {
            console.log("SOME ERROR", error);
            setStatus("Failed")
            // this.setState({ errorMessage: errorMessage })
          });
      } else {
        const publicToken = new URLSearchParams(location.search).get("publicToken") || ""
        const data = {
          body: { "id": publicToken },
          headers: {
            "Content-Type": "application/json"
          }
        };
        API
          .post('api', '/payments/ksession', data)
          .then(response => {
            console.log("SOME RESPONSE", response)
            setInfo({
              status: 'checkout',
              response: response
            })
          })
          .catch(error => {
            console.log("SOME ERROR", error);
            setStatus("Failed")
            // this.setState({ errorMessage: errorMessage })
          });
      }

    }
  })

  function getContentByStatus(status) {
    var rootURL = process.env.SITE_URL 
    switch (status) {
      case "Checkout":
        return <div className="app__notice"></div>
      case "Confirm":
        return <div className="app__notice"></div>
      case "Failed":
        return <div className="app__notice">Failed to process payment, please try again later.</div>
      case "Loaded":
        return (
          <div>
            <div className="app__notice"></div>
            <div id='checkout-container' />
          </div>
        )
      case "Loading":
        return <div className="app__notice">Please Wait</div>
    }
  }



  return (
    <div>
      <div className="app__body">
        {getContentByStatus(status)}
      </div>

    </div>
  )

}

export default KlarnaHosted

