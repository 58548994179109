import React from 'react'
import KlarnaHosted from '../components/klarnaHosted'
import { Layout } from "../components/layoutEmpty"

const Payment = ({location}) => (
        <section className="section has-background-white has-text-black  has-text-centered">
            <KlarnaHosted location={location} />
        </section>
)

Payment.Layout = Layout
export default Payment

